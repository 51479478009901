export let head = [
  { title: '标题', Alias: 'key', fixed: true, width: 100 },

  { title: '分类1', Alias: 'a0' },
  { title: '分类2', Alias: 'a1' },
  { title: '分类3', Alias: 'a2' },
  { title: '分类4', Alias: 'a3' },
  { title: '分类5', Alias: 'a4' },
  { title: '分类6', Alias: 'a5' },
  { title: '分类7', Alias: 'a6' },
  { title: '分类8', Alias: 'a7' },
  { title: '分类9', Alias: 'a8' },
  { title: '分类10', Alias: 'a9' },
  { title: '分类11', Alias: 'a10' },
  { title: '分类12', Alias: 'a11' },
  { title: '分类13', Alias: 'a12' },
  { title: '分类14', Alias: 'a13' },
  { title: '分类15', Alias: 'a14' },
  { title: '分类16', Alias: 'a15' },
  { title: '分类17', Alias: 'a16' },
  { title: '分类18', Alias: 'a17' },
  { title: '分类19', Alias: 'a18' },
  { title: '分类20', Alias: 'a19' },
  { title: '分类21', Alias: 'a20' },
  { title: '分类22', Alias: 'a21' },
  { title: '分类23', Alias: 'a22' },
  { title: '分类24', Alias: 'a23' },
  { title: '分类25', Alias: 'a24' }
];

export const calcList = [
  // {
  //   title: '计数',
  //   type: 'count'
  // },
  {
    title: '总和',
    type: 'sum'
  },
  {
    title: '最大',
    type: 'maxNum'
  },
  {
    title: '最小',
    type: 'smallest'
  },
  // {
  //   title: '中位数',
  //   type: 'median'
  // },
  {
    title: '平均',
    type: 'arithmeticMean'
  },
  // {
  //   title: '样本标准差',
  //   type: 'standardDeviation'
  // },
  // {
  //   title: '样本方差',
  //   type: 'variance'
  // },
  // {
  //   title: '总体标准差',
  //   type: 'popStandardDeviation'
  // },
  // {
  //   title: '总体方差',
  //   type: 'popVariance'
  // },
  // {
  //   title: '加权平均',
  //   type: 'weightedAverage'
  // }
];

export let table = [
  {
    key: 'Key1',
    a0: -160425000000,
    a1: -10653000000,
    a2: 150483000000,
    a3: 412098000000,
    a4: 412098000000,
    a5: 4696678000000,
    a6: 5108776000000,
    a7: 22088000000,
    a8: 22088000000,
    a9: 5695000000,
    a10: 27783000000,
    a11: 207000000,
    a12: 29000000,
    a13: 27961000000,
    a14: 25216000000,
    a15: 92022000000,
    a16: 24.002955814913825,
    a17: 18.674904239344452,
    a18: 5.96,
    a19: 5.97,
    a20: 7.754490071771455,
    a21: 17.63,
    a22: 1.03,
    a23: 1.03,
    a24: 1.03
  },
  {
    key: 'Key2',
    a0: -6738123283.68,
    a1: -4852502193.23,
    a2: 8288020493.16,
    a3: 234234876591.05,
    a4: 391708562002.6,
    a5: 1487493682256.5,
    a6: 1879202244259.1,
    a7: 12222659217.79,
    a8: 18102741280.26,
    a9: 6256458751.25,
    a10: 24359200031.51,
    a11: 532757134.97,
    a12: 654272279.83,
    a13: 24237684886.65,
    a14: 183084123913.71,
    a15: 206916289449.72,
    a16: 8.74882365636994,
    a17: 20.464904239344452,
    a18: 4.86,
    a19: 5.06,
    a20: 0.7129244882352106,
    a21: 20.28,
    a22: 1.01,
    a23: 1.05,
    a24: 1.05
  },
  {
    key: 'Key3',
    a0: -1047516.43,
    a1: 452839,
    a2: -33600394.2,
    a3: 912313639.5,
    a4: 912258663.92,
    a5: 128512625.45,
    a6: 1040771289.37,
    a7: -35373745.37,
    a8: -35986066.44,
    a9: -5031720.38,
    a10: -41017786.82,
    a11: 568487.04,
    a12: 701.24,
    a13: -40450001.02,
    a14: 57089307.59,
    a15: 22662761.61,
    a16: -158.78941436740462,
    a17: 59.99067092512209,
    a18: -3.89,
    a19: -3.8,
    a20: -0.21538296443044638,
    a21: 5.8481,
    a22: -0.232,
    a23: -0.2268,
    a24: -0.2268
  },
  {
    key: 'Key4',
    a0: -38810236.39,
    a1: -30018264.6,
    a2: 30650773.02,
    a3: 1383672974.5,
    a4: 1482050430.04,
    a5: 888497221.4,
    a6: 2370547651.44,
    a7: 6716482.5,
    a8: 4135757.09,
    a9: -635039.13,
    a10: 3500717.96,
    a11: 26482.5,
    a12: 57812.56,
    a13: 3469387.9,
    a14: 124100628.43,
    a15: 89354181.83,
    a16: 4.628498639121835,
    a17: 14.983292024845124,
    a18: 0.32,
    a19: 0.42,
    a20: 0.0289557923766625,
    a21: 1.3072,
    a22: 0.0042,
    a23: 0.0063,
    a24: 0.0063
  },
  {
    key: 'Key5',
    a0: 765012461.52,
    a1: -5112247.44,
    a2: 242449243.95,
    a3: 7705009914.69,
    a4: 8261423312.74,
    a5: 16205491086.9,
    a6: 24466914399.64,
    a7: 222779662.63,
    a8: 240396824.58,
    a9: 76055673.16,
    a10: 316452497.74,
    a11: 44566.87,
    a12: 1328567.58,
    a13: 315168497.03,
    a14: 1074182987.55,
    a15: 1354279105.66,
    a16: 17.750907001023545,
    a17: 40.72505836905862,
    a18: 2.86,
    a19: 2.87,
    a20: 0.1795926915942179,
    a21: 5.7074,
    a22: 0.1643,
    a23: 0.165,
    a24: 0.165
  },
  {
    key: 'Key6',
    a0: -4494156.15,
    a1: -284375.15,
    a2: 87308906.75,
    a3: 91324620.7,
    a4: 102061557.9,
    a5: 330993446.57,
    a6: 433055004.47,
    a7: -9989822.79,
    a8: -11987048.29,
    a9: 537393.79,
    a10: -11449654.5,
    a11: 9389150.68,
    a12: 63982.27,
    a13: -2124486.09,
    a14: 120301403.87,
    a15: 119206368.63,
    a16: -10.05571130784642,
    a17: 14.44759819289195,
    a18: 0.33,
    a19: -10.37,
    a20: 0.25201154476715704,
    a21: 0.2636,
    a22: 0.0009,
    a23: -0.0288,
    a24: -0.0288
  },
  {
    key: 'Key7',
    a0: -565061982.03,
    a1: -56156932.23,
    a2: 238866724.91,
    a3: 5121567511.78,
    a4: 5254260943.46,
    a5: 6541641662.52,
    a6: 11795902605.98,
    a7: -84238757.26,
    a8: -85324974.43,
    a9: 5578713.76,
    a10: -79746260.67,
    a11: 1512511.26,
    a12: 249107.38,
    a13: -78482856.79,
    a14: 777715070.09,
    a15: 581033998.15,
    a16: -14.685022683986295,
    a17: 39.61610427322634,
    a18: -1.96,
    a19: -1.52,
    a20: 0.08589870709241326,
    a21: 1.8418,
    a22: -0.0309,
    a23: -0.031,
    a24: -0.031
  },
  {
    key: 'Key8',
    a0: 2448774330.75,
    a1: -2098779903.56,
    a2: -218056954,
    a3: 8397417159.09,
    a4: 16680591407.56,
    a5: 28536071149.24,
    a6: 45216662556.8,
    a7: 423195842.67,
    a8: 1017712779.43,
    a9: 203732609.13,
    a10: 1221445388.56,
    a11: 9276491.88,
    a12: 2235894.28,
    a13: 1228485986.16,
    a14: 12436806522.39,
    a15: 13531636611.97,
    a16: 7.532248102380387,
    a17: 22.96611310970585,
    a18: 5.21,
    a19: 5.14,
    a20: -0.08454395678646226,
    a21: 3.2558,
    a22: 0.1665,
    a23: 0.1641,
    a24: 0.1641
  },
  {
    key: 'Key9',
    a0: 900981449.78,
    a1: -109848.84,
    a2: -570914515.65,
    a3: 1250888573.11,
    a4: 1594854175.15,
    a5: 2980304883.42,
    a6: 4575159058.57,
    a7: 9420879.69,
    a8: 11851230.06,
    a9: 3993228.16,
    a10: 15844458.22,
    a11: 5301739.17,
    a12: 33498.8,
    a13: 21112698.59,
    a14: 440258968.35,
    a15: 434695932.34,
    a16: 2.726326422288785,
    a17: 14.014696779437545,
    a18: -1.87,
    a19: 1.8,
    a20: -0.539263882399174,
    a21: 1.1815,
    a22: -0.0092,
    a23: 0.0089,
    a24: 0.0089
  },
  {
    key: 'Key10',
    a0: 151572890.35,
    a1: -248809261.38,
    a2: -395994231.09,
    a3: 4137968023.07,
    a4: 4176546506.78,
    a5: 10989764284.03,
    a6: 15166310790.81,
    a7: 250802157.71,
    a8: 241301028.23,
    a9: 92655204.26,
    a10: 333956232.49,
    a11: 1417586.84,
    a12: 2546068.46,
    a13: 332827750.87,
    a14: 1648725222.85,
    a15: 1988299840.24,
    a16: 12.13604826326765,
    a17: 46.2529462764023,
    a18: 5.53,
    a19: 5.4,
    a20: -0.664443159844943,
    a21: 6.9431,
    a22: 0.4306,
    a23: 0.4208,
    a24: 0.4208
  }
];
